@import "../../../theme/mixins.scss";

.pageWrapper {
  // display: flex;
  // flex-direction: column;
  // max-width: 1500px;
  height: auto;
  // padding: 20px;
  margin: 0 auto;
}
