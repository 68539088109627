@import '../../theme/mixins.scss';

.container {
  // @include theme-aware('background', 'color-twentytwo');
}

.Collections {
  // @include theme-aware('background', 'color-twentytwo');
  // background-color: black;
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  overflow: auto;
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1200px;
  height: auto;
  box-sizing: border-box;
  margin: 0 auto;

  // background-attachment: fixed;
  // background-color: #000000;
  // background-image: linear-gradient(
  //     to bottom,
  //     rgba(255, 153, 153, 0) 0%,
  //     rgba(255, 153, 153, 0) 75%,
  //     rgb(0, 0, 0) 100%
  //   ),
  //   url(https://stxnft.mypinata.cloud/ipfs/QmWUH9caud34GjXcq562duAWSwYRdV7G4B1PVaoUZ9tQiQ);
  // background-position: top center;
  // background-repeat: no-repeat;
  // background-size: 100% auto;
}

.FakeMintWrapper {
  display: block;
  // flex-direction: column;
  text-align: center;
  // justify-content: center;
  // margin: 0 auto;
  padding-bottom: 150px;
}

.FakeMintButton {
  width: 250px;
  margin: 0 auto;
}

.MintHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // justify-content: center;
  align-items: center;
  padding-bottom: 30px;
  padding-left: 15px;
  // max-width: 960px;
}

.MintLeftBar {
  // display: flex;
  // justify-content: left;
  // padding-bottom: 30px;
  width: 40%;
}

.MintRightBar {
  // justify-content: right;
  // align-items: baseline;
  width: 60%;
}

@media only screen and (max-width: 800px) {
  .MintLeftBar {
    width: 100%;
    text-align: center;
  }
  .MintRightBar {
    width: 100%;
  }
}

.MintImageBorder {
  // box-shadow: 0 0 12px 7px;
  border-radius: 6px;
  height: 138px;
  width: 138px;
  display: flex;
  // justify-content: center;
  align-items: center;
  // @include theme-aware('color', 'color-nine');
  // @include theme-aware("background", "color-one");
}

.MintImageBorderLarge {
  // box-shadow: 0 0 12px 7px;
  // border-radius: 6px;
  // width: 45%;
  // display: flex;
  // justify-content: center;
  align-items: center;
  // @include theme-aware('color', 'color-nine');
  // @include theme-aware("background", "color-one");
}

.MintImage {
  // background: #aaaaaa;
  border-radius: 6px;
  height: 122px;
  width: 122px;
  overflow: hidden;
}

.Banner {
  margin-top: 40px;
  text-align: center;
  max-width: 1200px;
  // height: "600px";
  // padding: 150px 30px;
  // background-image: url('../../assets/bomberHeader.png');
}

.Center {
  text-align: center;
  max-width: 1200px;
}

.BomberAnim {
  text-align: center;
  width: 100;
  height: auto;
  // padding: 150px 30px;
  // background-image: url('../../assets/bomberHeader.png');
}

.BannerLogo {
  width: 150px;
  border-radius: 6px;
  margin-bottom: 50px;
  background-clip: border-box;
}

.BannerImage {
  background-clip: border-box;
  width: 100%;
  height: auto;
  box-sizing: border-box;
}

.BannerTitle {
  color: white;
  font-size: 72px;
  margin-bottom: 150px;
  font-weight: 600;
  letter-spacing: 1.65px;
}

.BannerSubtitle {
  color: white;
  font-size: 42px;
  max-width: 1100px;
  text-align: left;
  margin: 0 auto 70px;
  text-align: center;
}

.BannerDescription {
  color: white;
  font-size: 22px;
  text-align: left;
  max-width: 800px;
  margin: 0 auto;
}

@media only screen and (max-width: 800px) {
  .Banner {
    padding: 100px 30px;
  }
  .BannerTitle {
    font-size: 40px;
    margin-bottom: 50px;
  }
  .BannerSubtitle {
    font-size: 20px;
    margin-bottom: 50px;
  }
  .BannerDescription {
    font-size: 18px;
  }
}

.Artists {
  // @include theme-aware('background', 'color-twentytwo');
  // @include theme-aware('color', 'color-eight');
  padding: 50px 50px 20px;
  max-width: 1000px;
  margin: 0 auto;
  // justify-content: center;
  // width: 100%;
  // height: 100vh;
}

.ArtistsHeader {
  font-size: 40px;
  margin-bottom: 30px;
}

.ArtistsFeatured {
  display: grid;
  // grid-auto-flow: column;
  grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
  font-size: 22px;
}

.Artist {
  margin: 10px 0;
  // text-decoration: underline;
}

.Artist a {
  // @include theme-aware('color', 'color-twentyfour');
  // text-decoration: underline;
}

.ArtistWebsite {
  font-style: italic;
  font-family: 'Roboto';
  // font-family: Arial, Helvetica, sans-serif;
  // @include theme-aware('color', 'element-hover-color');
  font-size: 18px;
  margin-top: 5px;
}

.PageWrapper {
  // @include theme-aware('background', 'color-twentytwo');
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  overflow: auto;
}

.PageWrapperContainer {
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1500px;
  height: auto;
  box-sizing: border-box;
  margin: 0 auto;
  // @include theme-aware('background', 'color-twentytwo');
}

.MintImageLarge {
  // background: #aaaaaa;
  border-radius: 6px;
  // width: 85%;
  overflow: hidden;
}

.MintImageSrc {
  object-fit: cover;
  border-radius: 6px;
}

.MintImageSrcLarge {
  object-fit: contain;
  width: 80%;
  border-radius: 6px;
}

.imgWrapper {
  width: 45%;
  display: inline-block;
  vertical-align: top;
  padding: 32px 45px 32px 0;
  margin-bottom: 24px;
}

.MintContentLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
}

.MintContentRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.MintTitle {
  // font-family: Roboto;
  font-weight: 500;
  font-size: 40px;
  letter-spacing: 1px;
  // padding-bottom: 30px;
  line-height: 72px;
  // @include theme-aware('color', 'color-eight');
}

.MintSummary {
  // font-family: Roboto;
  font-weight: 300;
  font-size: 18px;
  // letter-spacing: 1.54px;
  line-height: 26px;
  // max-width: 850px;
  width: 100%;
  padding-bottom: 20px;
  // text-align: center;
  // @include theme-aware('color', 'color-eight');
}

.MintWebsite {
  // font-family: Roboto;
  font-weight: 300;
  font-size: 18px;
  // letter-spacing: 1.54px;
  line-height: 26px;
  // max-width: 850px;
  width: 100%;
  // padding-bottom: 20px;
  // text-align: center;
  // @include theme-aware('color', 'color-eight');
}

.MintSummary > a {
  text-decoration: underline;
  // text-decoration-style: dotted;
  text-underline-offset: 2px;
  // @include theme-aware('color', 'color-eight');
}

.MintAltButton {
  width: 250px;
  margin-top: 20px;
}

.MintButton {
  width: 250px;
}

.AdminButton {
  width: 250px;
  margin-bottom: 20px;
}

.AdminDivider {
  width: 200px;
  margin-bottom: 20px;
  text-align: center;
}

.MintPrice {
  padding-top: 30px;
  text-align: center;
}

.MintPriceContainer {
  // font-family: Roboto;
  font-weight: 500;
  font-size: 24px;
  letter-spacing: 1.85px;
  display: inline-block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px 18px 10px;
  // @include theme-aware('color', 'color-eight');
}

.MintPriceIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.MintPriceTitle {
  font-family: Roboto;
  font-weight: 500;
  font-size: 10px;
  color: #d8d8e0;
  letter-spacing: 1.37px;
  padding-top: 10px;
}

.MintPriceCardContainer {
  padding-top: 20px;
  // min-height: calc(100vh - 240px);
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: space-between;
  // align-items: flex-start;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 25px;
}

// .MintPriceCardContainer > * {
//   margin: 10px;
// }

.MintPriceIcon {
  padding-right: 5px;
}

.MintPriceText {
  padding-left: 5px;
}

@media only screen and (max-width: 600px) {
  .MintPriceCardContainer > * {
    margin: 30px 0;
  }
}

.Tip {
  // @include theme-aware('color', 'color-eight');
}

.Tip a {
  // @include theme-aware('color', 'color-eight');
}

.MintpassBalance > a {
  text-decoration: underline;
  text-decoration-style: dotted;
  text-underline-offset: 2px;
  // @include theme-aware('color', 'color-eight');
}

.MintpassBalance {
  // font-family: Roboto;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 1.54px;
  line-height: 35px;
  max-width: 850px;
  width: 100%;
  // padding-bottom: 35px;
  margin-bottom: 10px;
  text-align: center;
  // @include theme-aware('color', 'color-two');
}

.MintpassBalanceContainer {
  // margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
