@import '../../theme/mixins.scss';

// @media only screen
//   and (min-width: 1024px) {
//   .HomePage {
//     height: calc(100vh - 125px);
//     display: flex;
//   }
// }

// @media only screen
//   and (max-width: 1023px) {
//   .HomePage {
//   }
// }

h1 {
  // font-family: Roboto;
  font-weight: 500;
  font-size: 45px;
  margin-bottom: 12px;
  // @include theme-aware("color", "color-twentyone");
  text-align: left;
}

h2 {
  // font-family: Roboto;
  font-weight: 400;
  letter-spacing: 1.54px;
  // @include theme-aware("color", "color-two");
}

.ListAuctionInput {
  padding-bottom: 20px;
}

@media only screen and (min-width: 1024px) {
  // .AuctionView {
  //   padding-left: 10px;
  //   padding-right: 30px;
  //   min-width: 540px;
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  // }
}

.HomePageInputContainer {
  padding-bottom: 18px;
}

.HomePageInputContainer > * {
  margin-top: 15px;
}

.HomePageSpacer {
  height: 6%;
}

hr {
  opacity: 0.1;
}

.HomePageInput {
  border: 1px solid #979797;
  border-radius: 6px;
  font-family: Roboto;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 1.23px;
  padding: 12px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  box-sizing: border-box;
  // @include theme-aware("border-color", "color-fifteen");
  // @include theme-aware("color", "color-two");
}

.HomePageCollectionsView {
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow-y: scroll;
}

.Highlight {
  @include theme-aware('color', 'color-seven');
}

.imgWrapper {
  width: 45%;
  display: inline-block;
  vertical-align: top;
  padding: 32px 45px 32px 0;
  margin-bottom: 24px;
}

.metaWrapper {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  padding-bottom: 125px;
  white-space: pre-wrap;
}

.trait {
  display: inline-block;
}

.nftData2 {
  width: 100%;
}

.metaWrapper h1 {
  font-size: 64px;
  letter-spacing: 4px;
  margin-bottom: 0px;
  margin-top: 32px;
  word-wrap: break-word;
  // @include theme-aware("color", "color-eleven");
}

.detailsWrapper {
  margin-bottom: 20px;
  // line-height: 23px;
  // font-style: italic;
  // font-size: small;
  // @include theme-aware("color", "color-eleven");
}

.detailsLineWrapper {
  margin-bottom: 10px;
  // line-height: 23px;
  // font-style: italic;
  // font-size: small;
  // @include theme-aware("color", "color-eleven");
}

.highBid {
  margin-bottom: 10px;
  // line-height: 23px;
  // font-style: italic;
  font-size: x-large;
  // @include theme-aware("color", "color-twentyfour");
}

.detailsWrapper a {
  // @include theme-aware("color", "color-twentyfour");
}

.time {
  // font-style: italic;
  font-size: large;
  // @include theme-aware("color", "color-eleven");
  font-weight: bolder;
  letter-spacing: 0px;
}

.note {
  font-style: italic;
  font-size: small;
  // @include theme-aware("color", "color-eleven");
  // font-weight: bolder;
  // letter-spacing: 0px;
}

.metaWrapper h2 {
  // margin-bottom: 45px;
  letter-spacing: -0.02em;
  margin-top: 0;
  font-size: 24px;
  color: #90989c;
  font-style: italic;
}

.metaWrapper h3 {
  font-weight: 500;
  // border-bottom: 1px solid #90989c;
  // padding-bottom: 12px;
  letter-spacing: -0.02em;
  // margin-bottom: 24px;
  font-size: 22px;
  // @include theme-aware("color", "color-eleven");
}

.detailsWrapper h3 {
  font-weight: 500;
  border-bottom: 1px solid #90989c;
  padding-bottom: 12px;
  letter-spacing: -0.02em;
  margin-bottom: 24px;
  font-size: 22px;
  // @include theme-aware("color", "color-eleven");
}

p {
  @include theme-aware('color', 'color-eleven');
}

.descriptionWrapper p {
  padding-left: 6px;
}

.ownerWrapper .ownerLink {
  padding-left: 6px;
}

.buttonWrapper {
  margin-bottom: 24px;
}

.ownerWrapper a {
  word-wrap: break-word;
  text-decoration: none;
  @include theme-aware('color', 'color-eleven');
}

.ownerWrapper a:active,
.ownerWrapper a:visited,
.ownerWrapper a:focus {
  @include theme-aware('color', 'color-eleven');
}

.ownerWrapper a:hover {
  text-decoration: underline;
  @include theme-aware('color', 'color-eleven');
}

.Renderer {
  box-shadow: 0px 0px 20px 0px #121213;
}

.descriptionWrapper,
.traitsWrapper,
.rarityRankWrapper,
.ownerWrapper {
  margin-bottom: 45px;
}

@media only screen and (max-width: 1023px) {
  .imgWrapper {
    width: 100%;
    display: block;
    padding: 0;
  }

  .metaWrapper {
    width: 100%;
    display: block;
  }

  .metaWrapper h1 {
    font-size: 36px;
  }
}

.ListedBadge {
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  position: absolute;
  margin-left: 10px;
  margin-top: 10px;
  z-index: 1;
  background: rgba(74, 255, 58, 0.733);
  border: 3px solid #09690f91;
  color: rgba(13, 65, 9, 0.459);
  border-radius: 5px;
  // font-family: Roboto;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 1.23px;
  text-align: center;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  font-size: 16px;
  letter-spacing: 1.23px;
  text-align: center;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  // @include theme-aware("stroke", "color-eightteen");
  // @include theme-aware("color", "color-twelve");
  // @include theme-aware("border-color", "color-eightteen");
}

.individualNftWrapper {
  position: relative;
}
