@import "../../theme/mixins.scss";

.Collections {
  padding-top: 30px;
}

.MintTopBar {
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
}

.MintImageBorder {
  box-shadow: 0 0 12px 7px;
  border-radius: 6px;
  height: 138px;
  width: 138px;
  display: flex;
  justify-content: center;
  align-items: center;
  @include theme-aware("color", "color-nine");
  @include theme-aware("background", "color-one");
}

.MintImage {
  background: #aaaaaa;
  border-radius: 6px;
  height: 122px;
  width: 122px;
  overflow: hidden;
}

.MintContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.MintTitle {
  font-family: Roboto;
  font-weight: 500;
  font-size: 24px;
  letter-spacing: 2.78px;
  padding-bottom: 30px;
  @include theme-aware("color", "color-eight");
}

.MintSummary {
  font-family: Roboto;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 1.54px;
  line-height: 35px;
  max-width: 850px;
  width: 100%;
  padding-bottom: 35px;
  text-align: center;
  @include theme-aware("color", "color-eight");
}

.MintSummary > a {
  text-decoration: underline;
  text-decoration-style: dotted;
  text-underline-offset: 2px;
  @include theme-aware("color", "color-eight");
}

.MintAltButton {
  width: 300px;
  margin-top: 20px;
}

.MintButton {
  width: 300px;
}

.AdminButton {
  width: 300px;
  margin-bottom: 20px;
}

.AdminDivider {
  width: 300px;
  margin-bottom: 20px;
  text-align: center;
}

.MintPrice {
  padding-top: 40px;
  text-align: center;
}

.MintImageSrc {
  object-fit: cover;
}

.MintPriceContainer {
  font-family: Roboto;
  font-weight: 500;
  font-size: 24px;
  letter-spacing: 1.85px;
  display: inline-block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px 18px 10px;
  @include theme-aware("color", "color-eight");
}

.MintPriceIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.MintPriceTitle {
  font-family: Roboto;
  font-weight: 500;
  font-size: 10px;
  color: #d8d8e0;
  letter-spacing: 1.37px;
  padding-top: 10px;
}

.MintPriceCardContainer {
  padding-top: 60px;
  min-height: calc(100vh - 240px);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.MintPriceCardContainer > * {
  margin: 30px;
}

.MintPriceIcon {
  padding-right: 5px;
}

.MintPriceText {
  padding-left: 5px;
}

@media only screen and (max-width: 600px) {
  .MintPriceCardContainer > * {
    margin: 30px 0;
  }
}

.Tip {
  @include theme-aware("color", "color-eight");
}

.Tip a {
  @include theme-aware("color", "color-eight");
}

.MintpassBalance > a {
  text-decoration: underline;
  text-decoration-style: dotted;
  text-underline-offset: 2px;
  @include theme-aware("color", "color-eight");
}

.MintpassBalance {
  font-family: Roboto;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 1.54px;
  line-height: 35px;
  max-width: 850px;
  width: 100%;
  // padding-bottom: 35px;
  margin-bottom: 10px;
  text-align: center;
  @include theme-aware("color", "color-two");
}

.MintpassBalanceContainer {
  // margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
