@import "../../../../theme/mixins.scss";

.Actions {
  display: flex;
  // flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  flex-wrap: wrap;
}

.Spacer {
  height: 100%;
  width: 10px;
}

.Price {
  border-radius: 6px;
  box-sizing: border-box;
  // font-family: Roboto;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1.23px;
  @include theme-aware("background", "color-two");
  @include theme-aware("color", "color-twenty");
  @include theme-aware("stroke", "color-twenty");
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.PriceSymbol {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  padding-right: 5px;
}

.SoldFor {
  padding-right: 7px;
}

.PriceContainer {
  display: flex;
  width: 100%;
  display: flex;
  justify-content: center;
}

.SoldBadge {
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  position: absolute;
  top: 15px;
  left: 15px;
  background: rgba(255, 69, 58, 0.37);
  border: 3px solid #ff453a;
  border-radius: 5px;
  // font-family: Roboto;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 1.23px;
  text-align: center;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  font-size: 16px;
  letter-spacing: 1.23px;
  text-align: center;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  @include theme-aware("stroke", "color-twentythree");
  @include theme-aware("color", "color-twentythree");
  @include theme-aware("border-color", "color-twentythree");
}

.ListedBadge {
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  position: absolute;
  top: 15px;
  left: 15px;
  background: rgba(74, 255, 58, 0.733);
  border: 3px solid #09690f91;
  color: rgba(13, 65, 9, 0.459);
  border-radius: 5px;
  // font-family: Roboto;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 1.23px;
  text-align: center;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  font-size: 16px;
  letter-spacing: 1.23px;
  text-align: center;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  // @include theme-aware("stroke", "color-eightteen");
  // @include theme-aware("color", "color-twelve");
  // @include theme-aware("border-color", "color-eightteen");
}

.PendingBadge {
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  position: absolute;
  top: 15px;
  left: 15px;
  background: rgba(255, 255, 255, 0.733);
  border: 3px solid #00000091;
  color: rgba(0, 0, 0, 0.459);
  border-radius: 5px;
  // font-family: Roboto;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 1.23px;
  text-align: center;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  font-size: 16px;
  letter-spacing: 1.23px;
  text-align: center;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  // @include theme-aware("stroke", "color-eightteen");
  // @include theme-aware("color", "color-twelve");
  // @include theme-aware("border-color", "color-eightteen");
}

.PriceText {
  padding-left: 10px;
}
