@import "../../theme/mixins.scss";

.MintAltButton {
  width: 250px;
  // margin-top: 20px;
}

.MintButton {
  width: 250px;
  margin: 5px auto 20px;
}

.MintPrice {
  text-align: center;
}

.MintPriceContainer {
  font-weight: 500;
  font-size: 24px;
  letter-spacing: 1.85px;
  display: inline-block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px 18px 10px;
  @include theme-aware("color", "color-eight");
}

.MintPriceIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.MintPriceTitle {
  font-family: Roboto;
  font-weight: 500;
  font-size: 10px;
  color: #d8d8e0;
  letter-spacing: 1.37px;
  padding-top: 10px;
}

.MintPriceIcon {
  padding-right: 5px;
}

.MintPriceText {
  padding-left: 5px;
}