@import "../../theme/mixins.scss";

.TextInput {
  width: 100%;
  box-sizing: border-box;
  font-family: Roboto;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 1.54px;
  border-width:0px;
  border:none;
  outline: 0;
  padding: 8px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 4px;
  @include theme-aware('background', 'color-input');
  @include theme-aware('color', 'color-two');
}

.TextError {
  font-family: Roboto-Bold;
  font-size: 700;
  font-size: 12px;
  color: #D93229;
  letter-spacing: 0.62px;
}

.TextInputError {
  border: solid #D93229;
  border-width: 1px;
}