@import "../../../theme/mixins.scss";

.Container {
  // position: absolute;
  // top: 80px;
  // left: 0;
  // right: 0;
  // margin: 0 auto;
  display: flex;
  // flex-direction: column;
  gap: 10px;
  // justify-content: center;
  // max-width: 620px;
  margin-bottom: 20px;
}

.Step {
  // cursor: pointer;
  color: #dcdde2;
}

.CurrentStep {
  // cursor: pointer;
  text-decoration: underline;
}
