@import '../../../theme/mixins.scss';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  // margin-bottom: 30px;
  margin: 0 auto;
  padding: 30px;
  max-width: 1200px;
  font-size: 15px;
}

.Nav {
  text-align: center;
  display: flex;
  align-items: center;
  gap: 20px;
}

.NavItem {
  @include theme-aware('background', 'color-nine');
}

.NavItem:hover {
  @include theme-aware('background', 'color-nine');
  @include theme-aware('stroke', 'color-one');
}
