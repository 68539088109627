@import "../../theme/mixins.scss";

.Mint {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items:center;
  max-width: fit-content;
}

.MintAltButton {
  width: 250px;
  // margin-top: 20px;
}

.MintButton {
  width: 250px;
  margin: 5px auto 20px;
}

.MintPrice {
  text-align: center;
}

.MintPriceContainer {
  font-weight: 500;
  font-size: 24px;
  letter-spacing: 1.85px;
  display: inline-block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px 18px 10px;
  @include theme-aware("color", "color-eight");
}

.MintPriceIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.MintPriceTitle {
  font-family: Roboto;
  font-weight: 500;
  font-size: 10px;
  color: #d8d8e0;
  letter-spacing: 1.37px;
  padding-top: 10px;
}

.MintPriceIcon {
  padding-right: 5px;
}

.MintPriceText {
  padding-left: 5px;
}

.Tip {
  @include theme-aware("color", "color-eight");
}

.Tip a {
  @include theme-aware("color", "color-eight");
}

.MintpassBalance > a {
  text-decoration: underline;
  text-decoration-style: dotted;
  text-underline-offset: 2px;
  @include theme-aware("color", "color-eight");
}

.MintpassBalance {
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 1.54px;
  line-height: 35px;
  max-width: 850px;
  width: 100%;
  margin-bottom: 10px;
  text-align: center;
  @include theme-aware("color", "color-two");
}

.MintpassBalanceContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
