@import "../../theme/mixins.scss";

// @media only screen
//   and (min-width: 1024px) {
//   .HomePage {
//     height: calc(100vh - 125px);
//     display: flex;
//   }
// }

// @media only screen
//   and (max-width: 1023px) {
//   .HomePage {
//   }
// }

// h1 {
//   // font-family: Roboto;
//   font-weight: 500;
//   font-size: 45px;
//   margin-bottom: 30px;
//   @include theme-aware("color", "color-twentyone");
//   text-align: center;
// }

// h2 {
//   // font-family: Roboto;
//   font-weight: 400;
//   letter-spacing: 1.54px;
//   @include theme-aware("color", "color-two");
// }

// @media only screen and (min-width: 1024px) {
//   .HomePageView {
//     padding-left: 10px;
//     padding-right: 30px;
//     min-width: 540px;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//   }
// }

.HomePageInputContainer {
  padding-bottom: 18px;
}

.HomePageInputContainer > * {
  margin-top: 15px;
}

.HomePageSpacer {
  height: 6%;
}

hr {
  opacity: 0.1;
}

.HomePageInput {
  border: 1px solid #979797;
  border-radius: 6px;
  font-family: Roboto;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 1.23px;
  padding: 12px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  box-sizing: border-box;
  @include theme-aware("border-color", "color-fifteen");
  @include theme-aware("color", "color-two");
}

.HomePageCollectionsView {
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow-y: scroll;
}

.Highlight {
  @include theme-aware("color", "color-seven");
}

.Checkbox {
  width: 150px;
  margin-right: 10px;
}

.EditorCheckbox {
  width: 220px;
  // margin-right: 10px;
}

.Editor {
  margin-bottom: 30px;
}

.CollectionName {
  margin-right: 10px;
  justify-content: center;
  flex-direction: column;
  display: flex;
}

.CollectionRow {
  display: flex;
  margin-bottom: 30px;
  width: 100%;
  align-items: center;
  text-align: center;
  // justify-content: center;
  @include theme-aware("color", "color-eight");
}

.CollectionRow a {
  @include theme-aware("color", "color-eight");
}

.SaveButton {
  width: 80px;
  height: 30px;
  // margin: 0 auto;
  // margin-bottom: 20px;
  margin-right: 10px;
}

.FetchPricesButton {
  width: 100px;
  height: 30px;
  // margin: 0 auto;
  // margin-bottom: 20px;
  margin-right: 10px;
}

.SaveMessage {
  color: darkgray;
}

.InputsContainer {
  width: 600px;
  padding-bottom: 18px;
}

.InputsContainer > * {
  margin-top: 15px;
}
