@font-face {
  font-family: "Gamma Sans Text 300";
  font-style: normal;
  font-weight: 300;
  src: local("Gamma Sans Text 300"),
    url("./fonts/Gamma-Sans-Text-360.woff2") format("woff2");
}

@font-face {
  font-family: "Gamma Sans Text 400";
  font-style: normal;
  font-weight: 400;
  src: local("Gamma Sans Text 400"),
    url("./fonts/Gamma-Sans-Text-400.woff2") format("woff2");
}

@font-face {
  font-family: "Gamma Sans Text 500";
  font-style: normal;
  font-weight: 500;
  src: local("Gamma Sans Text 500"),
    url("./fonts/Gamma-Sans-Text-440.woff2") format("woff2");
}

@font-face {
  font-family: "Gamma Sans Display 400";
  font-style: normal;
  font-weight: 400;
  src: local("Gamma Sans Display 400"),
    url("./fonts/Gamma-Sans-Display-400.woff2") format("woff2");
}

@font-face {
  font-family: "Gamma Sans Display 500";
  font-style: normal;
  font-weight: 500;
  src: local("Gamma Sans Display 500"),
    url("./fonts/Gamma-Sans-Display-440.woff2") format("woff2");
}

body {
  margin: 0;
  /* font-family: "Gamma Sans Text 300"; */
  font-family: "Gamma Sans Text 300", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* z-index: 0; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pixelated {
  image-rendering: optimizeSpeed;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: optimize-contrast;
  image-rendering: crisp-edges;
  image-rendering: pixelated;
  -ms-interpolation-mode: nearest-neighbor;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
