@import '../../theme/mixins.scss';

.Collections {
  padding: 60px 0 30px;
}

.MintHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // justify-content: center;
  align-items: center;
  padding: 60px 0 30px;
  // padding-left: 15px;
  // max-width: 960px;
}

.MintLeftBar {
  // display: flex;
  // justify-content: left;
  // padding-bottom: 30px;
  width: 40%;
  // border-right: 1px solid lightgray;
  // padding-right: 70px;
  // margin-right: 70px;
}

.MintRightBar {
  // justify-content: right;
  // align-items: baseline;
  width: 60%;
  flex-wrap: wrap;
  // border-left: 1px solid lightgray;
  // margin-left: 50px;
  // padding-left: 50px;
  // border: 1px solid lightgray
}

.MintNumberButtons {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 800px) {
  .MintLeftBar {
    width: 100%;
    text-align: center;
  }
  .MintRightBar {
    width: 100%;
  }
}

.MintImageBorder {
  // box-shadow: 0 0 12px 7px;
  border-radius: 6px;
  height: 138px;
  width: 138px;
  display: flex;
  // justify-content: center;
  align-items: center;
  // @include theme-aware('color', 'color-nine');
  // @include theme-aware("background", "color-one");
}

.MintImageBorderLarge {
  // box-shadow: 0 0 12px 7px;
  // border-radius: 6px;
  // width: 45%;
  // display: flex;
  // justify-content: center;
  align-items: center;
  // @include theme-aware('color', 'color-nine');
  // @include theme-aware("background", "color-one");
}

.MintImage {
  // background: #aaaaaa;
  border-radius: 6px;
  height: 122px;
  width: 122px;
  overflow: hidden;
}

.MintImageLarge {
  // background: #aaaaaa;
  border-radius: 6px;
  // width: 85%;
  overflow: hidden;
}

.MintImageSrc {
  object-fit: cover;
  border-radius: 6px;
}

.MintImageSrcLarge {
  object-fit: contain;
  width: 80%;
  border-radius: 6px;
}

.imgWrapper {
  width: 45%;
  display: inline-block;
  vertical-align: top;
  padding: 32px 45px 32px 0;
  margin-bottom: 24px;
}

.MintContentLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
}

.MintContentRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.MintTitle {
  // font-family: Roboto;
  font-weight: 500;
  font-size: 40px;
  letter-spacing: 1px;
  // padding-bottom: 30px;
  line-height: 72px;
  // @include theme-aware('color', 'color-eight');
}

.MintSummary {
  // font-family: Roboto;
  font-weight: 300;
  font-size: 18px;
  // letter-spacing: 1.54px;
  line-height: 26px;
  // max-width: 850px;
  width: 100%;
  padding-bottom: 20px;
  // text-align: center;
  // @include theme-aware('color', 'color-eight');
}

.MintWebsite {
  // font-family: Roboto;
  font-weight: 300;
  font-size: 18px;
  // letter-spacing: 1.54px;
  line-height: 26px;
  // max-width: 850px;
  width: 100%;
  // padding-bottom: 20px;
  // text-align: center;
  // @include theme-aware('color', 'color-eight');
}

.MintWebsite a {
  text-decoration: underline;
  text-underline-offset: 2px;
  // @include theme-aware('color', 'color-eight');
}

.MintSummary > a {
  text-decoration: underline;
  // text-decoration-style: dotted;
  text-underline-offset: 2px;
  // @include theme-aware('color', 'color-eight');
}

.MintAltButton {
  width: 250px;
  margin-top: 20px;
}

.MintButton {
  width: 250px;
}

.AdminButton {
  width: 250px;
  margin-bottom: 20px;
}

.AdminDivider {
  width: 200px;
  margin-bottom: 20px;
  text-align: center;
}

.MintPrice {
  padding-top: 30px;
  text-align: center;
}

.MintPriceContainer {
  // font-family: Roboto;
  font-weight: 500;
  font-size: 24px;
  letter-spacing: 1.85px;
  display: inline-block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px 18px 10px;
  // @include theme-aware('color', 'color-eight');
}

.MintPriceIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.MintPriceTitle {
  font-family: Roboto;
  font-weight: 500;
  font-size: 10px;
  color: #d8d8e0;
  letter-spacing: 1.37px;
  padding-top: 10px;
}

.MintPriceCardContainer {
  padding-top: 20px;
  padding-bottom: 50px;
  // min-height: calc(100vh - 240px);
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: space-between;
  // align-items: flex-start;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 25px;
}

.MintPriceCardContainer > * {
  // margin-right: 20px;
  // margin-bottom: 10px;
}

.Card {
}

.MintPriceIcon {
  padding-right: 5px;
}

.MintPriceText {
  padding-left: 5px;
}

@media only screen and (max-width: 600px) {
  .MintPriceCardContainer > * {
    margin: 30px 0;
  }
}

.Tip {
  // @include theme-aware('color', 'color-eight');
}

li a {
  // @include theme-aware('color', 'color-eight');
  text-decoration: underline;
  text-underline-offset: 2px;
}

.MintpassBalance > a {
  text-decoration: underline;
  text-decoration-style: dotted;
  text-underline-offset: 2px;
  // @include theme-aware('color', 'color-eight');
}

.MintpassBalance {
  // font-family: Roboto;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 1.54px;
  line-height: 35px;
  max-width: 850px;
  width: 100%;
  // padding-bottom: 35px;
  margin-bottom: 10px;
  text-align: center;
  // @include theme-aware('color', 'color-two');
}

.MintpassBalanceContainer {
  // margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
