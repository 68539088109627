@import '../../theme/mixins.scss';

// @media only screen
//   and (min-width: 1024px) {
//   .HomePage {
//     height: calc(100vh - 125px);
//     display: flex;
//   }
// }

// @media only screen
//   and (max-width: 1023px) {
//   .HomePage {
//   }
// }

h1 {
  // font-family: Roboto;
  font-weight: 500;
  font-size: 45px;
  margin-bottom: 30px;
  // @include theme-aware('color', 'color-twentyfive');
}

// h2 {
//   // font-family: Roboto;
//   font-weight: 400;
//   letter-spacing: 1.54px;
//   @include theme-aware('color', 'color-two');
// }

h3 {
  // font-family: Roboto;
  font-weight: 400;
  letter-spacing: 1.54px;
  // @include theme-aware('color', 'color-two');
}

@media only screen and (min-width: 1024px) {
  .HomePageView {
    padding-left: 10px;
    padding-right: 30px;
    min-width: 540px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.HomePageInputContainer {
  padding-bottom: 18px;
}

.HomePageInputContainer > * {
  margin-top: 15px;
}

.HomePageSpacer {
  height: 6%;
}

hr {
  opacity: 0.1;
}

.HomePageInput {
  border: 1px solid #979797;
  border-radius: 6px;
  font-family: Roboto;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 1.23px;
  padding: 12px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  box-sizing: border-box;
  // @include theme-aware('border-color', 'color-fifteen');
  // @include theme-aware('color', 'color-two');
}

.Grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.HomePageCollectionsView {
  padding: 20px;
  // box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  // overflow-y: scroll;
}

.Highlight {
  // @include theme-aware('color', 'color-seven');
}

.MintPriceCardContainer {
  padding-top: 20px;
  // min-height: calc(100vh - 240px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  // display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  // gap: 25px;
  // width: 300px;
}

.MintPriceCardContainer > * {
  margin-right: 30px;
  max-width: 300px;
}
