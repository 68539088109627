@import "../../theme/mixins.scss";

.CheckboxInput {
  width: 100%;
  box-sizing: border-box;
  font-family: Roboto;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 1.54px;
  border-width: 0px;
  border: none;
  outline: 0;
  padding: 8px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 4px;
  @include theme-aware("background", "color-one");
  @include theme-aware("color", "color-two");
}
